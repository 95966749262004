import caseStudy from "../images/slicing/home/casestudy.png"
import species360 from "../images/slicing/home/species360.jpeg"
import blockChain from "../images/slicing/home/blockchain.jpeg"
import nurseio from "../images/slicing/home/nurseio.jpeg"
import customer from "../images/slicing/home/customer1.jpeg"
import customer2 from "../images/slicing/home/customer2.jpg"
import customer3 from "../images/slicing/home/customer3.jpg"
import banner1 from "../images/slicing/home/banner1.1.jpg"
import banner2 from "../images/slicing/home/banner2.1.jpg"
import banner5 from "../images/slicing/home/banner3.jpg"
import mvpsports from "../images/slicing/home/mvpsports.jpg"
import mvpsports2 from "../images/slicing/home/mvpsports2.jpg"

export const clientData = [
  {
    subHeading: "What we have done",
    heading: "CASE STUDY",
    description: `GTEN developed an automation framework for our application in a very short span of time using HP QTP on Telerik Controls which are not yet done by anyone else. GTEN’s ability to work in dynamic environment with ever changing functionality and ensuring availability and reliability of automation framework reduced our manual testing efforts by more than 50%.`,
    imageSrc: caseStudy,
    altText: "Image Not Found",
    clientName: "Hassan Syed",
    clientRelation: "CIO, ISIS",
    clientImage: customer,
  },
  {
    subHeading: "What we have done",
    heading: "CASE STUDY",
    description: `GTEN worked with us to develop and implement a testing plan for our software. They brought expertise to the project that we sorely needed. Their team worked hard and gave us quality testing and results. In addition, GTEN is very easy to work with – they understood our needs and was very flexible in working with us.`,
    imageSrc: caseStudy,
    altText: "Image Not Found",
    clientName: "Elisabeth Hunt",
    clientRelation:
      "Director of Training and Technical Support at International Species Information System",
    clientImage: customer3,
  },
  {
    subHeading: "What we have done",
    heading: "CASE STUDY",
    description: `GTEN successfully put place Quality Assurance activity within Scrum/Kanban organizations. Manual daily functional testings, regression testings rigorously documented and maintained. Their achievements contribute to guarantee high quality deliverable in production for the benefit of end users, development team business owning acceptance testing.

    GTEN is a reliable partner and knows how to deal with difficult situations, they have a very good understanding of the industry standards and product expectations.`,
    imageSrc: caseStudy,
    altText: "Image Not Found",
    clientName: "Hugues Le Bars",
    clientRelation: "CTO, Emagister",
    clientImage: customer2,
  },
]
export const caseStudyData = [
  [
    {
      heading:
        "Journey to Continuous Integration & Development using GTEN Test Engineering Capabilities.",
      altText: "Image Not Found",
      caseStudyImage: species360,
      redirect: "/species360/",
    },
    {
      heading:
        "Real time tagging, validation for global logistics company using Blockchain.",
      altText: "Image Not Found",
      caseStudyImage: blockChain,
      redirect: "/blockchain-logistics-company/",
    },
    {
      heading:
        "Intelligent Cloud platform for healthcare professionals with omnichannel experience.",
      altText: "Image Not Found",
      caseStudyImage: nurseio,
      redirect: "/nurseio/",
    },
  ],
  [
    {
      heading:
        "MVP Sports Clubs is a health facility management company with operations in West Michigan.",
      altText: "Image Not Found",
      caseStudyImage: mvpsports2,
      redirect: "/mvpsportsclub/",
    },
  ],
]

export const clientSuccessData = [
  {
    description:
      "Journey to Continuous Integration & Development using GTEN Test Engineering Capabilities.",
    altText: "Image Not Found",
    imageSrc: banner1,
    redirection: "/species360/",
    heading: "Species 360",
  },
  {
    description:
      "Real time tagging, validation for global logistics company using Blockchain.",
    altText: "Image Not Found",
    imageSrc: banner2,
    redirection: "/blockchain-logistics-company/",
    heading: "Blockchain Logistics Company",
  },
  {
    description:
      "Intelligent Cloud platform for healthcare professionals with omnichannel experience.",
    altText: "Image Not Found",
    imageSrc: banner5,
    redirection: "/nurseio/",
    heading: "Nurseio",
  },
  {
    description:
      "MVP Sports Clubs is a health facility management company with operations in West Michigan.",
    altText: "Image Not Found",
    imageSrc: mvpsports,
    redirection: "/mvpsportsclub/",
    heading: "MVP Sports Club",
  },
]

export const clientDataFilter = [
  {
    mainHeading:
      "Journey to Continuous Integration & Development using GTEN Test Engineering Capabilities.",
    altText: "Image Not Found",
    imageSrc: banner1,
    redirection: "/species360/",
    heading: "Species 360",
    serviceType: ["Digital Product Engineering"],
    industryType: ["Telecom & Media"],
    description:
      "The Customer Species360 facilitates international collaboration in the collection, sharing and analysis of knowledge on wildlife. Together, Species360 and their members help to improve animal care and welfare, and inform species conservation.",
  },
  {
    mainHeading:
      "Real time tagging, validation for global logistics company using Blockchain.",
    altText: "Image Not Found",
    imageSrc: banner2,
    redirection: "/blockchain-logistics-company/",
    heading: "Blockchain Logistics Company",
    serviceType: ["Digital Product Engineering"],
    industryType: ["Healthcare and Lifescience"],
    description:
      "Our client is one of the major code generations and monitoring companies for various products in the Healthcare and FMCG sector.",
  },
  {
    mainHeading:
      "Intelligent Cloud platform for healthcare professionals with omnichannel experience.",
    altText: "Image Not Found",
    imageSrc: banner5,
    redirection: "/nurseio/",
    heading: "Nurseio",
    serviceType: ["CX Innovation & Optimization"],
    industryType: ["Healthcare and Lifescience"],
    description:
      "Healthcare is a business which is very heavily regulated and involves verification of credentials of healthcare professionals, their certifications, permits in real time. Also during Covid due to heavy pressure on entire healthcare ecosystem it",
  },
  {
    mainHeading:
      "MVP Sports Clubs is a health facility management company with operations in West Michigan.",
    altText: "Image Not Found",
    imageSrc: mvpsports,
    redirection: "/mvpsportsclub/",
    heading: "MVP Sports Club",
    serviceType: ["CX Innovation & Optimization"],
    industryType: ["Healthcare and Lifescience"],
    description:
      "Healthcare is a business which is very heavily regulated and involves verification of credentials of healthcare professionals, their certifications, permits in real time. Also during Covid due to heavy pressure on entire healthcare ecosystem it",
  },
]

export const servicesFilterClientPage = [
  { id: "1001", label: "All" },
  { id: "1002", label: "Digital Business Strategy" },
  { id: "1003", label: "Digital Product Engineering" },
  { id: "1004", label: "CX Innovation & Optimization" },
  { id: "1005", label: "Data-Driven Business & Intelligence" },
  { id: "1006", label: "Identity, Access &amp; Privacy" },
  { id: "1007", label: "Core IT Modernization" },
]

export const industriesFilterClientPage = [
  { id: "2001", label: "All" },
  { id: "2002", label: "Healthcare and Lifescience" },
  { id: "2003", label: "Industrial" },
  { id: "2004", label: "Software & Hi-tech" },
  { id: "2005", label: "Telecom & Media" },
  { id: "2006", label: "Other Industries" },
]
